<template>
  <div class="expertise">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url(img/expertise/header-expertise.jpg)"
    >
      <div class="viewport">
        <h1>Discover new ways to gain an advantage</h1>
        <p>
          We help employers and individuals grow by sourcing highly skilled professionals and
          bespoke teams across various disciplines.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="areas">
        <h3>Areas of Expertise</h3>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="box">
            <img src="/img/icons/software-development-2x.png" alt="Software Development" />
            <h3>Software Development</h3>
            <p>
              Across different platforms including Java, .NET, Python, JavaScript, iOS, Android and
              other platforms.
            </p>
          </div>
          <div class="box">
            <img src="/img/icons/ux-ui-design-2x.png" alt="UX/UI Design" />
            <h3>UX/UI Design</h3>
            <p>
              Specialising in Web Design, UI, UX, User Interactions, Art Direction and all related
              specialist areas.
            </p>
          </div>
          <div class="box">
            <img src="/img/icons/quality-assurance-2x.png" alt="Quality Assurance" />
            <h3>Quality Assurance</h3>
            <p>
              Automated and Functional Software Testing through to QA Engineers, QA Leads and other
              disciplines.
            </p>
          </div>
          <div class="box">
            <img src="/img/icons/technical-writing-2x.png" alt="technical-writing-2x" />
            <h3>Technical Writing</h3>
            <p>
              Technical and Web Writers, Content Editors, Communication Specialists and Technical
              Documentation Engineers.
            </p>
          </div>
          <div class="box">
            <img src="/img/icons/icons8-workflow-100.png" alt="Technical Operations" />
            <h3>Technical Operations</h3>
            <p>
              DevOps and TechOps Engineers, System Administrators, Release Managers and Build
              Engineers.
            </p>
          </div>
          <div class="box">
            <img src="/img/icons/technical-support-2x.png" alt="Technical Support" />
            <h3>Technical Support</h3>
            <p>
              Technical Support Professionals, Help Desk Technicians, Support Agents and Customer
              Experience Leads.
            </p>
          </div>
          <div class="box">
            <img src="/img/icons/technical-management-2x.png" alt="Technical Management" />
            <h3>Technical Management</h3>
            <p>
              Chief Technical Officers, Product Owners, Project Manager, Business Analysts and
              TechOps Managers.
            </p>
          </div>
          <div class="box">
            <img src="/img/icons/technical-marketing-2x.png" alt="Technical Sales/Marketing" />
            <h3>Technical Sales/Marketing</h3>
            <p>
              B2B salespeople, Technical Pre-sales Engineers, Digital Marketing Experts and related
              professions.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="background-mid-section d-flex align-items-center"
      style="
        background: url('img/services/go-further-bg.jpg');
        background-size: cover;
        margin-bottom: 0;
        margin-top: 0;
      "
    >
      <div class="viewport">
        <h3>Services that go further</h3>
        <p>
          From direct hiring through to nearshoring and freelance projects, see how our people can
          help you grow in the areas that matter most.
        </p>
        <router-link to="/services" class="btn btn-primary">Learn more</router-link>
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>
<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Software Developers, Tech Support Professionals, etc | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.expertise {
  .areas {
    padding: 70px 0;
    h3 {
      text-align: center;
      margin: 40px 0 50px 0;
      font-size: 2.25rem;
      font-weight: 600;
      color: #383838;
    }
    .box {
      width: 24.5%;
      color: #000;
      text-align: center;
      margin-bottom: 40px;
      img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto 20px auto;
      }
      h3 {
        font-size: 28px;
        font-weight: 600;
        margin: 0 0 12px 0;
        word-spacing: 9999px;
      }
      p {
        font-size: 14px;
        padding: 0 40px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .expertise {
    .areas {
      h3 {
        margin: 12px 0;
      }
      .d-flex {
        display: block !important;
      }
      .box {
        width: 100%;
      }
    }
  }
}
</style>
